<!--
* @FileDescription: 学校角色管理
-->
<template>
  <div class="schoolRoleManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='schoolRoleManagement'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="学校角色管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
              <el-form-item label="角色名称">
                <el-select v-model="formInline.roleId" placeholder="选择状态" filterable clearable>
                  <el-option
                    v-for="item in roleNameData"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色状态">
                <el-select v-model="formInline.lockFlag" placeholder="选择状态" filterable clearable>
                  <el-option label="正常" value="ENABLE"></el-option>
                  <el-option label="禁用" value="DISABLE"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="versionEvent('add')" plain>创建学校角色</el-button>
            <!-- <el-button class="mainBtn" @click="batchOperaEvent('ENABLE')" plain>自动更新</el-button> -->
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
          >
            <el-table-column
              label="序号"
              align="center"
              width="55"
            >
              <template slot-scope="scope">
                {{
                  (formInline.page - 1) * formInline.pageSize + scope.$index + 1
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="roleName"
              label="角色名称"
              align="center">
            </el-table-column>
            <!-- <el-table-column
              prop="userCount"
              label="用户数"
              align="center">
              <template slot-scope="scope">
                <span @click="watchRoleListEvent(scope.row)">{{scope.row.userCount}}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="remarks"
              label="角色备注"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.remarks" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.remarks}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="lockFlag"
              label="状态"
              align="center"
              width="180">
              <template slot-scope="scope">
                {{scope.row.lockFlag == 'ENABLE' ? '启用' : '禁用'}}
                <el-switch
                  v-model="scope.row.lockFlag"
                  active-value="ENABLE"
                  inactive-value="DISABLE"
                  @change="chageRoleStatus(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center">
              <template slot-scope="scope">
                <span>{{$way.timestampReturnDate(scope.row.createTime, 'datetime')}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="180"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="versionEvent('edit',scope.row)">编辑</el-button>
                <el-button type="text" @click="jumpAuthority(scope.row)">权限管理</el-button>
                <el-button @click="deleteEvent(scope.row)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>

        <el-dialog class="compile" :title="!isType ? '新增角色' : '编辑角色'" :visible.sync="isShowVer" @close='closeEvent'>
          <el-form :rules="rules" :model="form" label-width="100px" ref="form">
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="form.roleName" placeholder="角色名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="角色备注">
              <el-input v-model="form.remarks" placeholder="角色备注" maxlength="25" clearable></el-input>
            </el-form-item>
            <el-form-item label="角色状态">
              <el-select v-model="form.lockFlag" placeholder="请选择角色状态" clearable>
                <el-option label="启用" value="ENABLE"></el-option>
                <el-option label="禁用" value="DISABLE"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="isShowVer = false">取 消</el-button>
            <el-button type="primary" @click="enterEvent('form')">确 定</el-button>
          </div>
        </el-dialog>

        <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
          <div slot="dialogContent" class="dia_opera">
            <img class="errIcon" src="../../assets/img/warn.png" alt="">
            <p>确定要删除该角色吗？</p>
            <div class="btn_area">
              <el-button @click="dialogDiyEvent">关闭</el-button>
              <el-button class="mainBtn" @click="doEvent">确定</el-button>
            </div>
          </div>
        </dialogDiy>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import publicChildren from '../publicChildren'
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
export default {
  name: 'schoolRoleManagement',
  components: {
    publicChildren,
    Pagination,
    dialogDiy
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        createTimeEnd: null,
        createTimeStart: null,
        lockFlag: null,
        page: 1,
        pageSize: 10,
        roleId: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      roleNameData: [],
      isShowVer: false,
      form: {
        roleName: null,
        remarks: null,
        lockFlag: 'ENABLE'
      },
      isType: false,
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      rowStatus: {},
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
    }
  },
  created() {
    this.getRoleDownEvent()
    this.getSchoolRoleTableEvent()
  },
  methods: {
    // 获取角色名称数据
    getRoleDownEvent() {
      this.$request.schoolRoleDownList().then( res=>{
        if(res.data.code == 0) {
          this.roleNameData = res.data.data;
        }
      })
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
    },
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getSchoolRoleTableEvent();
    },
    // 获取平台用户表格数据
    getSchoolRoleTableEvent() {
      this.$request.schoolRoleList(this.formInline).then( res => {
        if(res.data.code == 0){
          let {records, current, size, total} = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getSchoolRoleTableEvent();
    },
    chageRoleStatus(row) {
      let obj = {
        id: row.roleId
      }
      if(row.lockFlag == 'DISABLE') {
        this.$request.prohibitSchoolRole(obj).then(res => {
          if(res.data.code == 0){
            this.Success(res.data.msg)
          }
        }).catch(err => {
          row.lockFlag = 'ENABLE'
        })
      } else {
        this.$request.useSchoolRole(obj).then(res=>{
          if(res.data.code == 0) {
            this.Success(res.data.msg)
          }
        }).catch(err => {
          row.lockFlag = 'DISABLE'
        })
      }
      
    },
    versionEvent(type, row = {}) {
      this.isShowVer = !this.isShowVer;
      this.isType = type == 'edit' ? true : false;
      if(type != 'add') {
        this.editData(row)
      }
    },
    editData(row) {
      this.form.roleName = row.roleName;
      this.form.remarks = row.remarks;
      this.form.lockFlag = row.lockFlag;
      this.rowStatus = row;
    },
    closeEvent() {
      this.isShowVer = false;
      this.isType = false;
      this.clearEvent();
      this.resetForm('form');
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // clear
    clearEvent() {
      Object.assign(this.$data.form, this.$options.data().form)
      // this.form.lockFlag = 'ENABLE';
      // this.form.remarks = null;
      // this.form.roleName = null;
    },
    // 新增/编辑角色
    enterEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!this.isType) {
            this.addRoleEvent();
          } else {
            this.editRoleEvent();
          }
        }
      })
    },
    addRoleEvent() {
      let dto = this.form;
      this.$request.addSchoolRole(dto).then(res => {
        if(res.data.code == 0) {
            this.Success(res.data.msg);
            this.isShowVer = false;
            this.clearEvent();
            this.getRoleDownEvent();
            this.getSchoolRoleTableEvent();
        }
      })
    },
    editRoleEvent() {
      let dto = JSON.parse(JSON.stringify(this.form));
      dto.roleId = this.rowStatus.roleId;
      this.$request.updateSchoolRole(dto).then(res => {
        if(res.data.code == 0) {
            this.Success(res.data.msg);
            this.isShowVer = false;
            this.rowStatus = '';
            this.clearEvent();
            this.getRoleDownEvent();
            this.getSchoolRoleTableEvent();
        }
      })
    },
    // 删除
    deleteEvent(row) {
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    doEvent() {
      let id = this.rowStatus.roleId;
      this.$request.deleteSchoolRole({id}).then(res => {
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.rowStatus = {};
          this.Success(res.data.msg);
          // this.formInline.page = 1;
          this.getRoleDownEvent();
          this.getSchoolRoleTableEvent();
        }
      })
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
    },
    // 权限管理
    jumpAuthority(row) {
      this.$router.push({name: 'schoolAuthorityManagement', query: {
        id: row.roleId,
        breadcrumb: '权限管理'
      }})
    }
  },
}
</script>

<style lang='less'>
  .schoolRoleManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .compile {
      .el-dialog {
        width: 400px;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
</style>